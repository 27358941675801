<template>
  <div class="freeuse">
    <div class="main">
      <div class="free_input">
        <div class="input_model">手机号：</div>
        <div class="input_box">
          <input class="input_man" v-model="mobile" @blur="phonejc" />
        </div>
      </div>
      <!-- <div class="zhuyi1">注：ios（苹果）用户请点击右上方三个点(...)后点击刷新再进行操作</div> -->
      <div class="free_btn" @click="saoma">扫码确认</div>
    </div>
  </div>
</template>
<script>
import { Notify } from "vant";
export default {
  data() {
    return {
      mobile: "",
    };
  },
  created() {
    console.log(window.location.href);
  },
  methods: {
    //检测手机号
    phonejc() {
      console.log(this.mobile);
      if (/^1[3-9]\d{9}$/.test(this.mobile)) {
        localStorage.setItem("mobile", this.mobile);
      } else {
        this.mobile = "";
        Notify({ type: "primary", message: "手机号格式错误" });
      }
    },
    saoma() {
      if (!this.mobile) {
        Notify({ type: "primary", message: "请先填写手机号" });
        return;
      }
      this.$http.wechatsign({ url: window.location.href }).then((res) => {
        console.log(window);
        console.log(JSON.stringify(res));
        window.wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          //                                debug : true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: res.appId, // 必填，公众号的唯一标识
          timestamp: res.timestamp, // 必填，生成签名的时间戳
          nonceStr: res.nonceStr, // 必填，生成签名的随机串
          signature: res.signature, // 必填，签名，见附录1
          jsApiList: ["scanQRCode"], // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
        });
        window.wx.ready(() => {
          console.log("成功装备");
          console.log(window.wx);
          window.wx.scanQRCode({
            needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
            scanType: ["qrCode", "barCode"], // 可以指定扫二维码还是一维码，默认二者都有
            success: (res) => {
              console.log("成功", this.mobile, res);
              let result = res.resultStr.split("/");
              let deviceSn = result[result.length - 1];
              this.$http
                .apply({
                  deviceSn: deviceSn,
                  mobile: localStorage.getItem("mobile"),
                })
                .then((res) => {
                  console.log(res, "111111111111111111");
                  Notify({ type: "primary", message: res.msg });
                  setTimeout(() => {
                    window.location.href = "https://gzh.heiqingting.net/";
                  }, 2000);

                  // alert(res.msg);
                  // this.$router.push("/");
                  // if (res.code == 200) {
                  //   // alert(res.msg);
                  //   // this.$router.push("/");
                  // } else {
                  //   alert(res.msg);
                  // }
                });
            },
            error: function (res) {
              alert(res);
            },
          });
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.freeuse {
  width: 100%;
  height: 100%;
  background: #f8f8f8;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  .free_input {
    margin-top: 8px;
    width: 336px;
    height: 54px;
    border-bottom: 1px solid #d6dde5;
    display: flex;
    align-items: center;
    margin-bottom: 419px;
    .input_model {
      color: #2d3538;
      font-size: 16px;
    }
    .input_box {
      flex: 1;
      height: 100%;
      margin-bottom: 2px;
      .input_man {
        font-size: 14px;
        width: 100%;
        height: 100%;
        background: #f8f8f8;
      }
    }
  }
  .free_btn {
    width: 275px;
    height: 40px;
    background: linear-gradient(135deg, #35c3ff 0%, #1890ff 100%);
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    color: #ffffff;
    box-shadow: 0 0 0 gray, 0px 0px 0px gray, 0 2px 1px #35c3ff,
      0px 0px 0px gray;
    /* margin-bottom: 202px; */
    margin: 0 auto;
  }
}
</style>